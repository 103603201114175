import axios from "axios";
import { getCookie } from "cookies-next";

export const getwhitelabelcss = async (
  pageCode?: string
): Promise<CssPostLoginDataType | null> => {
  try {
    const response = await axios.post(`/api/general/getwhitelabelcss`, {
      pageCode,
    });
    return response.data;
  } catch (error) {
    console.info("getwhitelabelcss", error);
    return null;
  }
};

export const getCssPreAndPostLogin = async (
  pageCode?: string
): Promise<CssPostLoginDataType | ResponsePreloginType | null> => {
  let apikey = getCookie("userToken");
  try {
    const response = await axios.post(`/api/general/getCssPreAndPostLogin`, {
      pageCode,
      apikey,
    });
    return response.data;
  } catch (error) {
    console.info("getwhitelabelcss", error);
    return null;
  }
};
